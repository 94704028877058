<template>
  <router-view/>
</template>

<style lang="less">
html, body {
  padding: 0;
  margin: 0;
}
</style>
