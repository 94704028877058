import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';

const Main = () => import('@/views/main/main.vue');

const AllContents = () => import('@/views/main/allContents/allContents.vue');
const Resourcespost = () => import('@/views/main/allContents/resourcespost/resourcespost.vue');
const Comments = () => import('@/views/main/allContents/comments/comments.vue');

const Examine = () => import('@/views/main/examine/examine.vue');
const ResourcespostExamine = () => import('@/views/main/examine/resourcespostExamine/resourcespostExamine.vue');
const CommentsExamine = () => import('@/views/main/examine/commentsExamine/commentsExamine.vue');

const Feedback = () => import('@/views/main/feedback/feedback.vue');
const OpinionFeedback = () => import('@/views/main/feedback/opinionFeedback/opinionFeedback.vue');
const BugFeedback = () => import('@/views/main/feedback/bugFeedback/bugFeedback.vue');

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/main'
  },
  {
    path: '/main',
    name: 'main',
    component: Main,
    children: [
      {
        path: '/main',
        redirect: '/main/allContents'
      },
      {
        path: '/main/allContents',
        name: 'allContents',
        component: AllContents,
        children: [
          {
            path: '/main/allContents',
            redirect: '/main/allContents/comments'
          },
          {
            path: '/main/allContents/resourcespost',
            name: 'resourcespost',
            component: Resourcespost
          },
          {
            path: '/main/allContents/comments',
            name: 'comments',
            component: Comments
          }
        ]
      },
      {
        path: '/main/examine',
        name: 'examine',
        component: Examine,
        children: [
          {
            path: '/main/examine',
            redirect: '/main/examine/commentsExamine'
          },
          {
            path: '/main/examine/commentsExamine',
            name: 'commentsExamine',
            component: CommentsExamine
          },
          {
            path: '/main/examine/resourcespostExamine',
            name: 'resourcespostExamine',
            component: ResourcespostExamine
          }
        ]
      },
      {
        path: '/main/feedback',
        name: 'feedback',
        component: Feedback,
        children: [
          {
            path: '/main/feedback',
            redirect: '/main/feedback/opinionFeedback'
          },
          {
            path: '/main/feedback/opinionFeedback',
            name: 'opinionFeedback',
            component: OpinionFeedback
          },
          {
            path: '/main/feedback/bugFeedback',
            name: 'bugFeedback',
            component: BugFeedback
          }
        ]
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
